$font-family-base: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';

$headings-font-family: 'Ruda', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$primary: $v-light-color-primary;
$danger: $v-light-color-danger;
$body-color: $v-light-color-body;

$font-weight-normal: 300;
$headings-font-weight: 500;

$navbar-brand-height: 2.2rem;
$navbar-padding-y: 1rem;

$font-size-base: 1rem;

$navbar-toggler-focus-width: 1px;

$h1-font-size: $font-size-base * 3.5 !default;
$h2-font-size: $font-size-base * 3 !default;
$h3-font-size: $font-size-base * 2.75 !default;
$h4-font-size: $font-size-base * 2.5 !default;
$h5-font-size: $font-size-base * 2.25 !default;
$h6-font-size: $font-size-base !default;

$hover-background: $v-light-color-active;

$enable-shadows: true;
$btn-box-shadow: $v-light-btn-box-shadow;

$border-radius: $v-border-radius;
$border-color: $v-light-color-border;

$card-border-color: $v-light-color-border;
$card-border-width: 0.5px;
$card-cap-bg: $v-light-color-contrast;
