//
// Global
//

// Scroll padding for all scroll targets on page used with
// native CSS smooth scrolling
//
// https://caniuse.com/?search=scroll-padding

html {
  scroll-padding-top: 4.5rem;
}

aside,
section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.mb-section {
  margin-bottom: 4rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $v-light-color-emphasize;
}
