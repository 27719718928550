//
// Navbar
//

#mainNav {
  background-color: $white;

  .navbar-toggler {
    color: $v-light-color-emphasize;
    border: none;
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $v-light-color-emphasize;
        &:hover {
          color: $v-light-color-body;
        }
        &.active {
          color: $v-light-color-primary;
        }
      }
    }
  }

  .logo {
    height: $navbar-brand-height;
  }
}

body.fixed-navbar-top {
  padding-top: calc(
    #{$navbar-brand-height} + #{$navbar-brand-padding-y} * 2 + #{$navbar-padding-y} *
      2
  );
}
